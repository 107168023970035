import { ProjectPageRouteTree, routeTree } from 'pages/routeTree';
import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';
import {
  PrimaryStateUnit,
  makeDerivedUnit,
  makePrimaryUnit,
} from 'utils/State';
import { makeMapping } from 'utils/collection';

import * as F from '../FormWithSteps';
import { ConstructorMode } from '../types';
import { serverProjectDataUnit } from '../units';
import { ConstructorConfig } from './Config';

export const defaultCompactConstructorStepSections = [
  [
    F.steps.Concept.step,
    F.steps.InputDataForm.step,
    F.steps.Instruction.step,
    F.steps.FindingsSettings.step,
    F.steps.ProjectAnnouncement.step,
  ],
];

export const compactConstructorStepSections = {
  default: defaultCompactConstructorStepSections,
  mesch: [...defaultCompactConstructorStepSections, [F.steps.Access.step]],
  moderator: [
    ...defaultCompactConstructorStepSections,
    [F.steps.AdvancedSettings.step],
  ],
};

export const defaultFullConstructorStepSections = [
  [
    F.steps.Concept.step,
    F.steps.InputDataForm.step,
    F.steps.Instruction.step,
    F.steps.FindingsSettings.step,
    F.steps.Conclusions.step,
    F.steps.ProjectAnnouncement.step,
  ],

  [F.steps.TeacherMaterials.step, F.steps.ParticipantMaterials.step],

  [F.steps.Access.step, F.steps.Recommendations.step],
];

export const fullConstructorStepSections = {
  default: defaultFullConstructorStepSections,
  moderator: [
    ...defaultFullConstructorStepSections,
    [F.steps.AdvancedSettings.step],
  ],
};

const makeStepSections = (mode: ConstructorMode, user: M.User | null) => {
  switch (mode) {
    case 'compact': {
      if (process.env.RAZZLE_PROFILE !== 'MESCH') {
        return compactConstructorStepSections['mesch'];
      }
      if (user?.role === USER_ROLE.moderator) {
        return compactConstructorStepSections['moderator'];
      }
      return compactConstructorStepSections['default'];
    }
    case 'full': {
      if (user?.role === USER_ROLE.moderator) {
        return fullConstructorStepSections['moderator'];
      }
      return fullConstructorStepSections['default'];
    }
  }
};

type Args = {
  user: M.User | null;
  mode: ConstructorMode;
  headerContentUnit?: PrimaryStateUnit<React.FC | null>;
  projectUUID?: M.Project['uuid'];
  projectPageRouteTree?: ProjectPageRouteTree;
  onProjectSave?(project: M.Project): void;
  onQuestionnaireDelete?(event: { questionnaire: M.QuestionnaireData }): void;
};

export function makeConstructorConfig({
  mode,
  user,
  headerContentUnit,
  projectUUID,
  projectPageRouteTree,
  onProjectSave,
  onQuestionnaireDelete,
}: Args): ConstructorConfig {
  const stepsSections = makeStepSections(mode, user);

  const steps = stepsSections.flat();

  const stepKeyToStep = makeMapping(
    steps,
    x => x.key,
    x => x,
  );

  const activeStepUnit = makePrimaryUnit<F.StepKey>(
    process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes'
      ? mode === 'compact'
        ? 'findingsSettings'
        : 'findingsSettings'
      : steps[0].key,
  );

  const isLastStepUnit = makeDerivedUnit(activeStepUnit).getUnit(
    active => steps[steps.length - 1].key === active,
  );

  const getProjectUUID = () => {
    if (projectUUID) {
      return projectUUID;
    }
    const params =
      routeTree.LANG.project.constructor.PROJECT_UUID.getRouteParams();

    if (params) {
      return params.project_uuid;
    }
    return serverProjectDataUnit.getState()?.uuid || null;
  };

  return {
    steps,
    stepKeyToStep,
    activeStepUnit,
    isLastStepUnit,
    stepsSections,
    mode,
    headerContentUnit,
    projectPageRouteTree,
    onProjectSave,
    onQuestionnaireDelete,
    getProjectUUID,
  };
}
