import React from 'react';

import * as M from 'types/serverModels';
import { useFormElementState } from 'utils/FormState';

import { MapQuestion } from '../../../../../subfeatures';
import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const mapFormElementState = useFormElementState<M.Location | null>(null);
  const background = instance.background.units.value.useState();

  return (
    <MapQuestion.Component
      formElementState={mapFormElementState}
      image={background || undefined}
    />
  );
}

export const Component = React.memo(PreviewModeForm);
