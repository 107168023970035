import debounce from 'debounce';
import React, { useCallback, useEffect, useMemo } from 'react';

import { routeTree } from 'pages/routeTree';
import { API, I18n, Routing } from 'services';
import { ServiceInterface } from 'services/API/services/utils/makeService';
import * as M from 'types/serverModels';
import {
  DerivedStateUnit,
  PrimaryStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';
import { ProjectWrite } from 'utils/business';
import { useIsMountedRef } from 'utils/react';
import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../config/configContext';
import { serverProjectDataUnit } from '../units';
import {
  conclusionsCallStateUnitUnit,
  getMaterialInstances,
  makeProjectSharedParts,
  saveMaterialsCallStateUnitUnit,
} from './shared';
import { Conclusions, saveMaterials } from './steps';

export type ServiceData<Service extends ServiceInterface<any, any>> = {
  callStateUnit: Service extends ServiceInterface<any, infer Output>
    ? PrimaryStateUnit<API.CallState<Output>>
    : never;
  call: Service extends ServiceInterface<infer Input, any>
    ? (input: Input) => void
    : never;
};

export type DraftServiceData<Service extends ServiceInterface<any, any>> = {
  callStateUnit: Service extends ServiceInterface<any, infer Output>
    ? PrimaryStateUnit<API.CallState<Output>>
    : never;
};

type ProjectWriteContextData = {
  status: ServiceData<typeof API.services.project.status>;
  callStateUnit: DerivedStateUnit<API.CallState<any>>;
  saveProject: debounce.DebouncedFunction<() => Promise<M.Project | null>>;
  saveProjectWithoutDebounce(): Promise<M.Project | null>;
};

export const ProjectWriteContext =
  createRequiredContext<ProjectWriteContextData>();

export function ProjectWriteContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const { steps, mode, onProjectSave } = useRequiredContext(
    ConstructorConfigContext,
  );

  const isMountedRef = useIsMountedRef();

  const writeDraft = useMemo(() => {
    const callStateUnit = API.services.project.post.makeCallStateUnit();

    return {
      callStateUnit,
      post: API.services.project.post.makeCall(callStateUnit),
      put: API.services.project.put.makeCall(callStateUnit),
      patch: API.services.project.patch.makeCall(callStateUnit),
    };
  }, []);

  // status

  const status = useMemo(() => {
    const callStateUnit = API.services.project.status.makeCallStateUnit();
    return {
      callStateUnit,
      call: API.services.project.status.makeCall(callStateUnit),
    };
  }, []);

  const callStateUnit = useMemo(() => {
    const saveMaterialsCallStateUnit = makeMappingUnitFromUnit(
      saveMaterialsCallStateUnitUnit,
    );
    const conclusionsCallStateUnit = makeMappingUnitFromUnit(
      conclusionsCallStateUnitUnit,
    );
    return makeDerivedUnit(
      writeDraft.callStateUnit,
      saveMaterialsCallStateUnit,
      conclusionsCallStateUnit,
    ).getUnit((writeDraft, saveMaterials, conclusions): API.CallState<any> => {
      if (writeDraft.kind === 'initial') {
        if (!saveMaterials && !conclusions) {
          return writeDraft;
        }
        return [saveMaterials, conclusions]
          .filter((x): x is API.CallState<any> => x !== null)
          .reduce(API.sumCallStates);
      }
      return [writeDraft, saveMaterials, conclusions]
        .filter((x): x is API.CallState<any> => x !== null)
        .reduce(API.sumCallStates);
    });
  }, [writeDraft.callStateUnit]);

  const saveProject: () => Promise<M.Project | null> = useCallback(async () => {
    if (!isMountedRef.current) return null;

    if (callStateUnit.getState().kind === 'pending') {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(saveProject());
        }, 100);
      });
    }
    const saveMaterialsPromise = saveMaterials(getMaterialInstances());
    const conclusionsPromise = Conclusions.saveConclusions();
    if (saveMaterialsPromise) {
      saveMaterialsCallStateUnitUnit.setState(
        API.makeCallStateUnitFromPromise(saveMaterialsPromise),
      );
    }
    if (conclusionsPromise) {
      conclusionsCallStateUnitUnit.setState(
        API.makeCallStateUnitFromPromise(conclusionsPromise),
      );
    }

    try {
      await saveMaterialsPromise;
      await conclusionsPromise;

      const projectSharedParts = makeProjectSharedParts();

      const serverProject = serverProjectDataUnit.getState();

      const project = steps.reduce<M.ProjectWriteData>((acc, x) => {
        return {
          ...acc,
          ...x.getProjectData?.({ serverProject }),
        };
      }, projectSharedParts as M.ProjectWriteData);

      const projectData: M.ProjectWriteData = {
        ...project,
        is_pb_project: mode === 'compact',
      };

      if (serverProject?.uuid) {
        const difference = ProjectWrite.difference(serverProject, projectData);

        if (Object.keys(difference).length > 0) {
          writeDraft.patch({
            uuid: serverProject.uuid,
            project: difference,
          });
        } else {
          return Promise.resolve(serverProject);
        }
      } else {
        writeDraft.post({ project: projectData });
      }
      return new Promise((resolve, reject) => {
        const unsubscribe = writeDraft.callStateUnit.subscribe({
          name: 'redirector',
          callback: callState => {
            switch (callState.kind) {
              case 'successful': {
                unsubscribe();
                resolve(callState.data);
                break;
              }
              case 'error': {
                unsubscribe();
                reject(null);
              }
            }
          },
        });
      });
    } catch {
      writeDraft.callStateUnit.setState({
        kind: 'error',
        message: 'failed to save',
      });
      return Promise.reject();
    }
  }, [callStateUnit, isMountedRef, mode, steps, writeDraft]);

  const saveProjectWithDebounce = useMemo(
    () => debounce(saveProject, 1000),
    [saveProject],
  );

  const saveProjectBindContext = useMemo(
    () => saveProjectWithDebounce.bind(null),
    [saveProjectWithDebounce],
  );

  useEffect(() => {
    return () => {
      saveProjectWithDebounce.clear();
    };
  }, [saveProjectWithDebounce]);

  useEffect(() => {
    return writeDraft.callStateUnit.subscribe({
      name: 'redirector',
      callback: callState => {
        if (callState.kind === 'successful') {
          serverProjectDataUnit.setState(callState.data);

          if (typeof onProjectSave === 'function') {
            onProjectSave(callState.data);
          } else {
            const params = routeTree.LANG.project.constructor.getRouteParams();

            if (params && params.rest?.[0] !== callState.data.uuid) {
              Routing.getHistory()?.replace(
                routeTree.LANG.project.constructor.PROJECT_UUID.getPath({
                  routeParams: {
                    LANG: I18n.activeLangStateUnit.getState(),
                    PROJECT_UUID: callState.data.uuid,
                  },
                }),
              );
            }
          }
        }
      },
    });
  }, [onProjectSave, writeDraft.callStateUnit]);

  useEffect(() => {
    if (mode === 'full' && serverProjectDataUnit.getState() === null) {
      saveProjectBindContext();
    }
  }, [mode, saveProjectBindContext]);

  return (
    <ProjectWriteContext.Provider
      status={status}
      callStateUnit={callStateUnit}
      saveProjectWithoutDebounce={saveProject}
      saveProject={saveProjectBindContext}
    >
      {children}
    </ProjectWriteContext.Provider>
  );
}
