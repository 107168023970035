import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { AnswerVariant } from 'utils/business';
import { nonEmptyString } from 'utils/validators';

import * as Questions from './questions';

export function makeQuestionInstanceFromServerQuestion(
  question: M.Question | M.QuizQuestion,
  language: TS.Language,
): Questions.QuestionInstance {
  const t = I18n.makeGetOptionalMultilingTranslation(language);

  const msis = Questions.makeSharedInstanceStateConstructor({
    id: question.uuid,
    title: t(question.title),
    explanation: t(question.description),
    image: question.image,
    questionIsRequired: !((question as M.Question)?.optional ?? true),
    visibility: (question as M.Question)?.visibility,
    answerVisibility: (question as M.Question)?.answer_visibility,
  });

  const makeVariant = (x: M.Variant) =>
    AnswerVariant.makeVariantFromServerVariant({
      variant: x,
      validators: [nonEmptyString(isRequired)],
      getMultilingTranslation:
        I18n.makeGetMultilingTranslation(getProjectLanguage()),
    });

  switch (question.type) {
    case 'date':
      return Questions.Date.makeInstance(msis);

    case 'draw':
      return Questions.Map.makeInstance(msis, true);

    case 'file':
      return Questions.File.makeInstance(msis, question.file_types);
    case 'image':
      return Questions.Image.makeInstance(msis, {
        hasMultipleImages:
          (question as M.ImageQuestion)?.control?.multiple ?? false,
        maxImages: (question as M.ImageQuestion)?.max ?? null,
        minImages: (question as M.ImageQuestion)?.min ?? null,
      });

    case 'map':
      return Questions.Map.makeInstance(
        msis,
        false,
        !!question.settings?.background,
        question.settings?.background || null,
      );

    case 'match':
      throw new Error(
        `question ${question.uuid} has "match" type. Question with this type is not supported`,
      );

    case 'multi_choice':
      return Questions.VariantSelection.makeInstance(msis, {
        hasMultipleChoice: true,
        variants: question.variants.map(makeVariant),
        displayKind: 'radio',
      });

    case 'single_choice':
      return Questions.VariantSelection.makeInstance(msis, {
        hasMultipleChoice: false,
        variants: question.variants.map(makeVariant),
        displayKind: (question as M.SingleChoiceQuestion)?.control?.dropdown
          ? 'select'
          : 'radio',
      });

    case 'number':
      return Questions.Number.makeInstance(
        msis,
        'quantity' in question ? question.quantity ?? null : null,
      );

    case 'probe':
      return Questions.SensorData.makeInstance(msis, question.probe || null);

    case 'sound':
      return Questions.Sound.makeInstance(msis);

    case 'string':
      return Questions.Text.makeInstance(msis, false);

    case 'text':
      return Questions.Text.makeInstance(msis, true);

    case 'video':
      return Questions.Video.makeInstance(msis);
  }
}
