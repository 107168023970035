import { FormElementState } from 'utils/FormState';

type MultiChoiceQuestionState = Record<
  string,
  {
    valueState: FormElementState<boolean>;
    textState: FormElementState<string>;
  }
>;

export const makeServerMultipleChoice = (
  state: Record<string, FormElementState<MultiChoiceQuestionState>>,
) => {
  const entries = Object.entries(state);
  const answers = entries.reduce<Record<string, string[]>>((acc, [key, x]) => {
    return {
      ...acc,
      [key]: Object.entries(x.units.value.getState() || {}).reduce<string[]>(
        (acc, [key, y]) => {
          if (y.valueState.units.value.getState()) {
            return [...acc, key];
          }

          return acc;
        },
        [],
      ),
    };
  }, {});

  const custom = entries.reduce<Record<string, Record<string, string>>>(
    (acc, [key, x]) => {
      return {
        ...acc,
        [key]: Object.entries(x.units.value.getState() || {}).reduce(
          (acc, [key, y]) => {
            const textState = y.textState.units.value.getState();
            const valueState = y.valueState.units.value.getState();
            if (textState && valueState) {
              return {
                ...acc,
                [key]: y.textState.units.value.getState(),
              };
            }

            return acc;
          },
          {},
        ),
      };
    },
    {},
  );

  return { answers, custom };
};
